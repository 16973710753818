<script>
/***
 Name: item
 Code: sa0ChunLuyu
 Time: 2021/11/16 10:56
 Remark: 内容
 */
import Create from './create.vue'
import Update from './update.vue'

export default {
  props: ['bid'],
  components: {Create, Update},
  watch: {
    bid() {
      this.reloadInfo(this.bid)
    },
  },
  data() {
    return {
      visible: false,
      create_data: {
        id: 0,
        name: '',
        com_name: '',
        task_type: 1,
        com_per: '',
        com_phone: '',
        get_time: '',
        remark: '',
      }
    }
  },
  mounted() {
    console.log('------------')
  },
  methods: {
    onClose() {
      this.visible = false
    },
    reloadInfo(id) {
      if (Number(id) === 0) {
        this.create_data = {
          id: 0,
          name: '',
          com_name: '',
          task_type: 1,
          com_per: '',
          com_phone: '',
          get_time: '',
          remark: '',
        }
        return;
      }
      this.$sa0.post({
        url: this.$api('预委托单信息'),
        data: {
          id: id
        }
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            this.create_data = response.data.info
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    }
  }
}
</script>
<template>
  <a-drawer
      title="预委托单"
      placement="right"
      :visible="visible"
      @close="onClose"
      width="80%"
  >
<!--    <Create :reloadInfo="reloadInfo" v-if="Number(create_data.id) === 0"></Create>-->
<!--    <Update :id="create_data.id" :reloadInfo="reloadInfo" v-if="Number(create_data.id) !== 0"></Update>-->
    <Update  :id="create_data.id" :reloadInfo="reloadInfo"></Update>
  </a-drawer>
</template>
<style scoped>

</style>
