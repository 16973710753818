<script>
    /***
     Name: create/create
     Code: sa0ChunLuyu
     Time: 2021/11/17 09:42
     Remark: 创建
     */
    import BeforeC from '../before/item.vue'

    export default {
        props: ['reloadList'],
        components: {BeforeC},
        data() {
            return {
                page: 1,
                count: 0,
                pagesize: 0,
                name: '',
                group_id: 0,
                group_number: '',
                before_show: false,
                visible: false,
                get_number: '',
                user_list: [],
                save_list: [],
                type_list: [],
                num_type: 1,
                link_draft: 0,
                yp_list: [],
                tableData: [],
                get_perser: [],
                types: [],

            }
        },
        watch: {
            $route: {
                handler() {
                    this.page = Number(this.$route.query.page ? this.$route.query.page : 1)
                    this.mountedDo()
                },
                deep: true
            },

        },
        mounted() {
            this.page = Number(this.$route.query.page ? this.$route.query.page : 1)
            this.mountedDo();
        },
        methods: {
            befName() {
                if ((typeof this.$refs.bef) === 'undefined') {
                    return '';
                } else {
                    return this.$refs.bef.create_data.name
                }
            },
            befId() {
                if ((typeof this.$refs.bef) === 'undefined') {
                    return 0;
                } else {
                    return this.$refs.bef.create_data.id
                }
            },
            mountedDo() {
                this.group_id = this.$route.params.id
                this.getInfo();
                this.Dandaalllist();
                this.Sampletypelist();
            },
            getInfo() {
                this.$sa0.post({
                    url: this.$api('Getspecitembyid'),
                    data: {
                        page: this.page,
                        group_id: this.$route.params.id,
                        danda_type: 2
                    },
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.name = response.data.result.info.name;
                            this.group_number = response.data.result.info.group_number;
                            this.link_draft = response.data.result.info.link_draft;
                            // console.log(this.link_draft)
                            this.tableData = response.data.result.list;
                            this.tableData1 = JSON.parse(JSON.stringify(response.data.result.list));
                            this.count = response.data.result.count;//分页
                            this.pagesize = response.data.result.pagesize;//分页
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },

            // 分页
            onChange(page) {
                this.$router.push({
                    query: {
                        page: page
                    }
                })
            },
            // 项目负责人所有列表
            Dandaalllist() {
                this.$sa0.post({
                    url: this.$api('Dandaalllist'),
                    data: {},
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.get_perser = response.data.list;
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },


            // 存放位置
            Sampletypelist() {
                this.$sa0.post({
                    url: this.$api('Sampletypelist'),
                    data: {
                        type: 3
                    },
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.types = response.data.list
                            let num01 = {
                                id: 0,
                                name: ''
                            }
                            this.types.unshift(num01)
                            console.log('---------', this.types)
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },

            saveClick(item) {
                let this_ = this
                let data = {
                    id: item.id,
                    status: item.status,
                    save_id: item.save_id,
                    remark: '',
                }
                if (item.status === 1) {
                    layer.prompt({title: '输入拒收原因', formType: 2}, function (remark, index) {
                        layer.close(index);
                        if (remark == '') return layer.msg('请输入原因')
                        data.remark = remark
                        this_.$sa0.post({
                            url: this_.$api('Set_spec_item_receiver'),
                            data: data
                        }).then((response) => {
                            this_.$sa0.response({
                                response: response.data,
                                then: (response) => {
                                    layer.msg('修改成功')
                                    this_.getInfo()
                                    console.log(JSON.stringify(data))
                                },
                                error: (response) => {
                                    layer.msg(response.message)
                                },
                            })
                        })
                    });
                }

                // console.log(data)
                // console.log(JSON.stringify(data))
                // return

            },



            jieshouClick(item) {
                let this_ = this
                let data = {
                    id: item.id,
                    status: item.status,
                    save_id: item.save_id,
                    remark: '',
                }
                if (item.status === 4) {

                    layer.open({
                        type: 1,
                        area: ['420px', '240px'], //宽高
                        content: 'html内容', })

                        this_.$sa0.post({
                            url: this_.$api('Set_spec_item_receiver'),
                            data: data
                        }).then((response) => {
                            this_.$sa0.response({
                                response: response.data,
                                then: (response) => {
                                    layer.msg('修改成功')
                                    this_.getInfo()
                                    console.log(JSON.stringify(data))
                                },
                                error: (response) => {
                                    layer.msg(response.message)
                                },
                            })
                        })








                    // layer.prompt({title: '请选择存放位置', formType: 2}, function (remark, index) {
                    //     layer.close(index);
                    //     if (remark == '') return layer.msg('请输入原因')
                    //     data.remark = remark
                    //     this_.$sa0.post({
                    //         url: this_.$api('Set_spec_item_receiver'),
                    //         data: data
                    //     }).then((response) => {
                    //         this_.$sa0.response({
                    //             response: response.data,
                    //             then: (response) => {
                    //                 layer.msg('修改成功')
                    //                 this_.getInfo()
                    //                 console.log(JSON.stringify(data))
                    //             },
                    //             error: (response) => {
                    //                 layer.msg(response.message)
                    //             },
                    //         })
                    //     })
                    // });
                }

                // console.log(data)
                // console.log(JSON.stringify(data))
                // return

            },

            // 类型转换
            wordType(status) {
                let type_arr = ['采集待处理', '采集中', '采集完成', '接收完成']
                if (type_arr.length >= Number(status)) {
                    return type_arr[Number(status) - 1]
                } else {
                    return status
                }
            },

            onClose() {
                this.visible = false
            },
            handleChange(value) {
                console.log(`selected ${value}`);
            },

            openBefore() {
                this.$refs.bef.visible = true
                let id = this.link_draft

                this.link_draft = id;
                this.$refs.bef.reloadInfo(id)
            },
        }
    }
</script>
<template>
    <div>
        <BeforeC :bid="link_draft" ref="bef"></BeforeC>
        <div class="group_info_wrapper">
            <a-space>
                <div class="info_wrapper">
                    <div class="my-3">
                        <a-space>
                            <div class="input_title_wrapper text-right"><b>样品组名称：</b></div>
                            <div>
                                <a-input v-model="name" class="input_wrapper" placeholder="请输入样品组名称"></a-input>
                            </div>
                        </a-space>
                    </div>
                    <div class="my-3">
                        <a-space>
                            <div class="input_title_wrapper text-right"><b>样品组编号：</b></div>
                            <div>
                                <a-input v-model="group_number" class="input_wrapper" placeholder="请输入样品组编号"></a-input>
                            </div>
                        </a-space>
                    </div>
                    <div class="my-3 before_wrapper">
                        <div @click="openBefore()" class="before_cover_wrapper"></div>
                        <a-space>
                            <div class="input_title_wrapper text-right"><b>预委托单信息：</b></div>
                            <div>
                                <a-input :value="befName()" class="input_wrapper" placeholder="请输入预委托单信息"></a-input>
                            </div>
                        </a-space>
                    </div>
                </div>
                <div class="line_wrapper">
                    <div class="code_wrapper"></div>
                    <a-button class="w-full mt-3" type="primary" size="small">选择其他样品组条码样式</a-button>
                    <a-button class="w-full mt-3" type="primary">打印样品组条码</a-button>
                </div>
            </a-space>
        </div>
        <div class="list_wrapper mt-5">
            <div class="list_button_wrapper">
                <a-space align="end">
                    <a-button type="primary" size="small">打印选中样品条码</a-button>
                    <div class="list_code_wrapper"></div>
                    <a-button type="primary" size="small">选择其他样品条码样式</a-button>
                </a-space>
            </div>
            <div>
                <table class="layui-table">
                    <thead>
                    <tr>
                        <th>样品类型</th>
                        <th>样品名称</th>
                        <th>样品编号</th>
                        <th>采集地点</th>
                        <th>经纬度</th>
                        <th>采集时间</th>
                        <th>采集人</th>
                        <th>接收人</th>
                        <th>样品状态</th>
                        <th>样品存放位置</th>
                        <th>拒收原因</th>
                        <th>操作</th>
                    </tr>
                    </thead>
                    <tbody>

                    <tr v-for="( item,key) in tableData" :key="key">
                        <td>{{item.type_name}}</td>
                        <td>{{item.name}}</td>
                        <td>{{item.in_number}}</td>
                        <td>
                            {{item.pick_space}}

                        </td>
                        <td>
                            <a-space>
                                经度:{{item.jing_dot}}<br>
                                纬度:{{item.wei_dot}}
                            </a-space>
                        </td>
                        <td>
                            {{item.pick_time}}

                        </td>
                        <td>{{item.pick_per_name}}</td>
                        <td>
                            {{item.get_per_name}}
                        </td>
                        <td>
                            {{wordType(item.status)}}
                        </td>
                        <td>
                            {{item.save_name}}
<!--                            <a-select v-model="item.save_id" placeholder="请选择" style="width: 120px">-->
<!--                                <a-select-option v-for="(item,key ) in types" :key="key" :value="item.id">-->
<!--                                    {{ item.name}}-->
<!--                                </a-select-option>-->
<!--                            </a-select>-->
                        </td>
                        <td>{{item.remark}}</td>
                        <td>
<!--                            <a-button type="primary" @click="saveClick(item)" v-if="tableData1[key].status===3">保存</a-button>-->
                            <a-button type="primary" @click="jieshouClick(item)" >接收</a-button>
                            <a-button type="primary" @click="saveClick(item)" class="ml20" >拒收</a-button>
                        </td>
                    </tr>
                    </tbody>
                </table>

                <a-pagination :current="page" :pageSize="pagesize" @change="onChange" :total="count" v-if="count>pagesize"/>
            </div>
        </div>

        <div>

        </div>
    </div>
</template>
<style scoped>
    .before_wrapper{
        position: relative;
        }

    .before_cover_wrapper{
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 1;
        }

    .list_code_wrapper{
        width: 200px;
        height: 100px;
        background: #00000040;
        }

    .code_wrapper{
        width: 300px;
        height: 100px;
        background: #00000040;
        }

    .line_wrapper{
        margin-left: 50px;
        }

    .input_title_wrapper{
        width: 180px;
        }

    .input_wrapper{
        width: 300px;
        }
    .ml20{ margin-left: 10px}
</style>
