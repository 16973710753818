<script>
    import moment from "moment";

    /***
     Name: table
     Code: sa0ChunLuyu
     Time: 2021/11/16 16:11
     Remark: 自定义表格
     */
    export default {
        data() {
            return {
                self_id: 0,
                edit_content: [],
                table_remark: '',
            }
        },
        mounted() {
        },
        methods: {
            getTableInfo(id) {
                this.self_id = id;
                this.$sa0.post({
                    url: this.$api('预委托单自定义信息'),
                    data: {
                        id: id
                    }
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.edit_content = response.data.list
                            this.table_remark = response.data.custom_remark
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },
            addItem(id) {
                this.$sa0.post({
                    url: this.$api('根据id获取委托单字段详情'),
                    data: {
                        id: id,
                    }
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            if (response.data.form_word.type !== 0) {
                                let con = response.data.form_word
                                let content = con.content
                                let value = ''
                                switch (con.type) {
                                    case 0:
                                    case 1:
                                    case 5:
                                    case 6:
                                    case 7:
                                        content = con.content;
                                        break;
                                    case 2:
                                    case 3:
                                    case 4:
                                        content = JSON.parse(con.content);
                                        break;
                                }
                                switch (con.type) {
                                    case 0:
                                    case 1:
                                    case 5:
                                        value = ''
                                        break;
                                    case 2:
                                        value = content[0]
                                        break;
                                    case 4:
                                        value = content[0]
                                        break;
                                    case 3:
                                        value = []
                                        break;
                                    case 7:
                                        switch (Number(content)) {
                                            case 0:
                                                value = moment().format('YYYY-MM-DD')
                                                break;
                                            case 1:
                                                value = moment().format('HH:mm:ss')
                                                break;
                                            case 2:
                                                value = moment().format('YYYY-MM-DD HH:mm:ss')
                                                break;
                                        }
                                        break;
                                    case 6:
                                        value = [];
                                        for (let i = 0; i < content; i++) {
                                            value.push('')
                                        }
                                        break;
                                }
                                this.edit_content.push({
                                    id: 0,
                                    word_id: con.id,
                                    type: con.type,
                                    title: con.name,
                                    key: '',
                                    content: content,
                                    value: value,
                                })
                            }
                            this.item_id = response.data.form_word.id

                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },
            saveTable() {
                console.log('this.self_id', this.self_id)
                let data = {
                    id: this.self_id,
                    custom_remark: this.table_remark,
                    table_arr: this.edit_content.map((item) => {
                        return {
                            id: item.id,
                            word_id: item.word_id,
                            content: JSON.stringify(item.value),
                        }
                    }),
                }
                this.$sa0.post({
                    url: this.$api('预委托单自定义信息修改'),
                    data: data
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            layer.msg('修改成功')
                            this.getTableInfo(this.self_id)
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },

            delThis(key) {
                this.edit_content.splice(key, 1)
            },
        }
    }
</script>
<template>
    <div>
        <table class="layui-table">
            <tbody>
            <tr v-for="(item,key) in edit_content" :key="key">
                <td class="table_title_bg">{{ item.title }}</td>
                <td>
                    <div class="h-full" v-if="item.type === 1">
                        {{item.value}}
                    </div>
                    <div class="input_select_wrapper" v-if="item.type === 2">
                        {{item.value}}
                    </div>
                    <div class="content_item_wrapper input_line_wrapper" v-if="item.type === 3">
            <span v-for="(i, k) in item.value">
               {{i}}
            </span>
                    </div>
                    <div class="content_item_wrapper input_line_wrapper" v-if="item.type === 4">
             <span v-for="(i, k) in item.value">
               {{i}}
            </span>
                    </div>
                    <div class="content_item_wrapper input_line_wrapper" v-if="item.type === 5">
                        {{item.value}}
                    </div>
                    <div class="content_image_item_wrapper" v-if="item.type === 6">
                        <span v-for="(i, k) in item.value" style="display: inline-block; margin-right: 10px; margin-top: 10px">  <img :src="i"/></span>

                    </div>
                    <div class="content_image_item_wrapper input_line_wrapper" v-if="item.type === 7">
                        {{item.value}}
                    </div>
                </td>
            </tr>
            <tr>
                <td class="table_title_bg">文字说明</td>
                <td colspan="5">
                    <div class="h-full">
                        {{table_remark}}
                    </div>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
</template>
<style scoped>
    td{
        line-height: 40px;
        padding: 0 10px;
        }

    .input_select_wrapper{
        width: 200px;
        }

    .table_title_bg{
        background: #eeeeee;
        }

    .layui-table td{
        padding: 0 10px;
        height: 40px;
        border-color: #bbbbbb;
        }

    .content_image_item_wrapper{
        width: 600px;
        }

    .image_item_wrapper{
        position: relative;
        overflow: hidden;
        margin: 10px 10px 10px 0;
        display: inline-block;
        width: 100px;
        height: 100px;
        line-height: 100px;
        text-align: center;
        border-radius: 6px;
        border: 1px solid #5a5e6630;
        background: #5a5e6605;
        }

    .image_image_wrapper img{
        width: 100%;
        height: 100%;
        }

    .image_image_wrapper{
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 1;
        }

    .image_input_wrapper{
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 2;
        width: 100%;
        height: 100%;
        background: #000;
        opacity: 0;
        cursor: pointer;
        }

    .input_line_wrapper{
        width: 500px;
        display: inline-block;
        }
</style>
