import { render, staticRenderFns } from "./update.vue?vue&type=template&id=6b31d5cc&scoped=true&"
import script from "./update.vue?vue&type=script&lang=js&"
export * from "./update.vue?vue&type=script&lang=js&"
import style0 from "./update.vue?vue&type=style&index=0&id=6b31d5cc&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/.pnpm/vue-loader@15.10.0_cutmzj7votxcrccm4agyl3addi/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6b31d5cc",
  null
  
)

export default component.exports