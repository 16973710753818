<script>
import moment from "moment";

/***
 Name: create
 Code: sa0ChunLuyu
 Time: 2021/11/16 11:13
 Remark: 修改
 */
import Info from './info.vue'
import TableC from './table.vue'

export default {
  components: {Info, TableC},
  props: ['reloadInfo', 'id'],
  data() {
    return {
      defaultActiveKey: 1,
      word_list: [],
    }
  },
  mounted() {
    this.mountedDo()
  },
  watch: {
    $route: {
      handler() {
        this.mountedDo()
      },
      deep: true
    },
  },
  methods: {
    mountedDo() {
      this.defaultActiveKey = this.$route.query.key ? Number(this.$route.query.key) : 1
      this.switchGetInfo()
    },
    switchGetInfo() {
      switch (this.defaultActiveKey) {
        case 1:
          this.$refs.info.getItemInfo(this.id)
          break;
        case 2:
          this.getWordList(this.id)
          break;
      }
    },
    getWordList(id) {
      this.$sa0.post({
        url: this.$api('获取委托单字段分级列表'),
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            this.word_list = response.data.list
            console.log('this.$refs.table.getTableInfo', id);
            this.$refs.table.getTableInfo(id)
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    wordClick(e) {
      let id = e[0] ? e[0] : this.item_id
      this.$refs.table.addItem(id)
    },
    groupClick(e) {
      let id = e[0] ? e[0] : this.item_id
      this.$refs.table.addItem(id)
    },
    collapseChange(key) {
      this.$router.push({
        query: {
          key: key
        }
      })
    },
  }
}
</script>
<template>
  <div style="width: 800px;">
    <div class="title_wrapper">预委托单</div>
    <a-space align="start">
      <a-collapse style="width:1000px;" accordion :activeKey="defaultActiveKey" @change="collapseChange">
        <a-collapse-panel key="1" header="基本信息">
          <Info ref="info" :reloadInfo="reloadInfo"></Info>
        </a-collapse-panel>
        <a-collapse-panel key="2" header="自定义信息">
          <TableC ref="table"></TableC>
        </a-collapse-panel>
      </a-collapse>

    </a-space>
  </div>
</template>
<style scoped>
.word_title_wrapper {
  height: 40px;
  line-height: 40px;
  background: #eeeeee;
  padding-left: 10px;
}

.word_wrapper {
  width: 200px;
  border: 1px solid #5a5e6620;
}

.title_wrapper {
  font-size: 26px;
  font-weight: bold;
}

.layui-table td {
  padding: 0 10px;
  height: 40px;
  border-color: #bbbbbb;
}

.image_image_wrapper img {
  width: 100%;
  height: 100%;
}
</style>
