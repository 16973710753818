<script>
    /***
     Name: info
     Code: sa0ChunLuyu
     Time: 2021/11/16 16:09
     Remark: 基本信息
     */
    export default {
        props: ['reloadInfo'],
        data() {
            return {
                create_data: {
                    name: '',
                    com_name: '',
                    task_type: 1,
                    com_per: '',
                    com_phone: '',
                    get_time: '',
                    remark: '',
                }
            }
        },
        mounted() {

        },
        methods: {
            getItemInfo(id) {
                this.$sa0.post({
                    url: this.$api('预委托单信息'),
                    data: {
                        id: id
                    }
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.create_data = response.data.info
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },

        }
    }
</script>
<template>
    <div>
        <div>
            <table class="layui-table">
                <colgroup>
                    <col width="120">
                    <col width="200">
                    <col width="100">
                    <col>
                </colgroup>
                <tbody>
                <tr>
                    <td>预委托单名称</td>
                    <td colspan="3">
                        {{create_data.name}}
                    </td>
                </tr>
                <tr>
                    <td>委托单位名称</td>
                    <td colspan="3">
                        {{create_data.com_name}}
                    </td>
                </tr>
                <tr>
                    <td>委托类型</td>
                    <td>
                        {{create_data.task_type_name}}
                    </td>
                    <td>办理时间</td>
                    <td>
                        {{create_data.get_time}}
                    </td>
                </tr>
                <tr>
                    <td>联系人</td>
                    <td>
                        {{create_data.com_per}}
                    <td>电话</td>
                    <td>
                        {{create_data.com_phone}}
                    </td>
                </tr>
                <tr>
                    <td>备注</td>
                    <td colspan="3">
                        {{create_data.remark}}
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>
<style scoped>
    td{
        line-height: 40px;
        padding: 0 10px;
        }

    .input_wrapper{
        width: 100%;
        height: 100%;
        }

    .layui-table td{
        padding: 0 10px;
        height: 40px;
        border-color: #bbbbbb;
        }

    .image_image_wrapper img{
        width: 100%;
        height: 100%;
        }
</style>
